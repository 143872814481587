$(() => {
  return setGaEvent();
});

// 動画(YouTube)のイベント
const setYoutubeEvent = () => {
  // クラス名 'ga-ev-b-X' の iframe がないページは処理しない
  if ($('iframe[class^="ga-ev-b"]').length == 0) {
    return;
  }

  $.getScript('https://www.youtube.com/player_api', () => {
    YT.ready(() => {
      $('iframe[class="ga-ev-b-4"]').each((i, element) => {
        const player = new YT.Player(element);
        player.addEventListener('onStateChange', (e) => {
          if (e.data == YT.PlayerState.PLAYING) {
            return gtag('event', 'click', {
              event_category: '動画',
              event_label: '動画_犬_お手入れ・しつけ_' + $(e.target.a).data('title'),
            });
          }
        });
      });

      $('iframe[class="ga-ev-b-5"]').each((i, element) => {
        const player = new YT.Player(element);
        player.addEventListener('onStateChange', (e) => {
          if (e.data == YT.PlayerState.PLAYING) {
            return gtag('event', 'click', {
              event_category: '動画',
              event_label: '動画_猫_お手入れ・しつけ_' + $(e.target.a).data('title'),
            });
          }
        });
      });

      $('iframe[class="ga-ev-b-6"]').each((i, element) => {
        const player = new YT.Player(element);
        player.addEventListener('onStateChange', (e) => {
          if (e.data == YT.PlayerState.PLAYING) {
            return gtag('event', 'click', {
              event_category: '動画',
              event_label: '動画_その他_お手入れ・しつけ_' + $(e.target.a).data('title'),
            });
          }
        });
      });
    });
  });
};

const setGaEvent = () => {
  $('a').on('click', (e) => {
    // 外部リンク
    var href = e.target.href || e.currentTarget.href;
    if (href && href.startsWith('http') && href.includes(document.domain) == false) {
      return gtag('event', 'click', {
        event_category: '外部リンク from ' + document.location.href,
        event_label: e.target.innerText ? e.target.innerText : href,
      });
    }
  });

  $('.ga-ev-a-1').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'お問い合わせ',
      event_label: 'お問い合わせ_フッター',
    });
  });

  $('.ga-ev-a-2').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'お問い合わせ',
      event_label: 'お問い合わせ_よくあるご質問',
    });
  });

  setYoutubeEvent();

  $('.ga-ev-d-1').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: '病院登録_ユーザー',
    });
  });

  $('.ga-ev-e-1').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: '体調記録_ユーザー',
    });
  });

  $('.ga-ev-f-1').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: '事前問診_ユーザー',
    });
  });

  $('.ga-ev-g-1').on('click', (e) => {
    const title = $(e.currentTarget).data('title');
    return gtag('event', 'action', {
      event_category: '広告',
      event_label: 'お役立ち_' + title + ' to ' + e.currentTarget.href,
    });
  });

  $('.ga-ev-g-2').on('click', (e) => {
    const title = $(e.currentTarget).data('title');
    return gtag('event', 'action', {
      event_category: '広告',
      event_label: 'お役立ち2_' + title + ' to ' + e.currentTarget.href,
    });
  });

  $('.ga-ev-h-1').on('click', (e) => {
    const title = $(e.currentTarget).data('title');
    return gtag('event', 'action', {
      event_category: '広告',
      event_label: '病院からのお知らせ_' + title,
    });
  });

  $('.ga-ev-j-1').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: '健康記録_アコーディオン_体調',
    });
  });

  $('.ga-ev-j-2').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: '健康記録_アコーディオン_体重',
    });
  });

  $('.ga-ev-j-3').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: '健康記録_アコーディオン_診察',
    });
  });

  $('.ga-ev-k-1').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_犬_性別',
    });
  });

  $('.ga-ev-k-2').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: 'ペット登録_犬_名前',
    });
  });

  $('.ga-ev-k-3').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: 'ペット登録_犬_誕生日',
    });
  });

  $('.ga-ev-k-4').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_犬_犬種',
    });
  });

  $('.ga-ev-k-5').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_犬_サイズ',
    });
  });

  $('.ga-ev-k-6').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_犬_写真登録',
    });
  });

  $('.ga-ev-k-7').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_猫_性別',
    });
  });

  $('.ga-ev-k-8').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: 'ペット登録_猫_名前',
    });
  });

  $('.ga-ev-k-9').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: 'ペット登録_猫_誕生日',
    });
  });

  $('.ga-ev-k-10').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_猫_猫種',
    });
  });

  $('.ga-ev-k-11').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_猫_サイズ',
    });
  });

  $('.ga-ev-k-12').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_猫_写真登録',
    });
  });

  $('.ga-ev-k-13').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_その他_性別',
    });
  });

  $('.ga-ev-k-14').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: 'ペット登録_その他_名前',
    });
  });

  $('.ga-ev-k-15').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: 'ペット登録_その他_誕生日',
    });
  });

  $('.ga-ev-k-16').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_その他_その他種',
    });
  });

  $('.ga-ev-k-17').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_その他_サイズ',
    });
  });

  $('.ga-ev-k-18').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_その他_写真登録',
    });
  });

  $('.ga-ev-k-19').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_犬_避妊去勢',
    });
  });

  $('.ga-ev-k-20').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_猫_避妊去勢',
    });
  });

  $('.ga-ev-k-21').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_その他_避妊去勢',
    });
  });

  $('.ga-ev-l-1').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: '病院登録_使っている病院',
    });
  });

  $('.ga-ev-l-2').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: '病院登録_名前_姓',
    });
  });

  $('.ga-ev-l-3').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: '病院登録_名前_名',
    });
  });

  $('.ga-ev-l-4').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: '病院登録_カナ_姓',
    });
  });

  $('.ga-ev-l-5').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: '病院登録_カナ_名',
    });
  });

  $('.ga-ev-l-6').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: '病院登録_診察券番号',
    });
  });

  $('.ga-ev-l-7').on('focus', () => {
    return gtag('event', 'click_form', {
      event_category: 'フォーム',
      event_label: '病院登録_電話番号',
    });
  });

  $('.ga-ev-m-1').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'フォーム',
      event_label: 'ペット登録_犬_性別_オス',
    });
  });

  // ペット手帳からのお届け便
  // $('.ga-ev-n-1').on('click', () => {...});のように記述した場合、
  // JavaScript内で$(this)が$(_this)に変換されてしまう。
  $('.ga-ev-n-1').click(function () {
    var product_name = $(this).find('.a_column').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_一覧',
      event_label: product_name,
    });
  });

  // ペット手帳からのお届け便(マイページ)
  $('.ga-ev-n-2').click(function () {
    var product_name = $(this).find('.a_column').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_マイページ',
      event_label: product_name,
    });
  });

  // 病院からのお届け便
  $('.ga-ev-o-1').click(function () {
    var product_name = $(this).parent().parent().find('.m_info-item').text();
    return gtag('event', 'click', {
      event_category: '病院からのお届け便_詳細',
      event_label: product_name,
    });
  });

  // 病院からのお届け便(マイページ)
  $('.ga-ev-o-2').click(function () {
    var product_name = $(this).find('.m_info-item').text();
    return gtag('event', 'click', {
      event_category: '病院からのお届け便_マイページ',
      event_label: product_name,
    });
  });

  // 健康記録新規作成
  $('.ga-ev-n-3').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_健康記録新規作成',
      event_label: product_name,
    });
  });

  // 過去の健康記録一覧
  $('.ga-ev-n-4').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_過去の健康記録一覧',
      event_label: product_name,
    });
  });

  // 過去の健康記録詳細
  $('.ga-ev-n-5').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_過去の健康記録詳細',
      event_label: product_name,
    });
  });

  // お知らせ一覧
  $('.ga-ev-n-6').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_お知らせ一覧',
      event_label: product_name,
    });
  });

  // コラム一覧
  $('.ga-ev-n-7').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_コラム一覧',
      event_label: product_name,
    });
  });

  // 月のコラム一覧
  $('.ga-ev-n-8').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_月のコラム一覧',
      event_label: product_name,
    });
  });

  // コラム詳細
  $('.ga-ev-n-9').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_コラム詳細',
      event_label: product_name,
    });
  });

  // コラム詳細(協賛)
  $('.ga-ev-n-10').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_コラム詳細(協賛)',
      event_label: product_name,
    });
  });

  // お手入れ・しつけ動画 一覧
  $('.ga-ev-n-11').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_お手入れ・しつけ動画_一覧',
      event_label: product_name,
    });
  });

  // 今日のおともだち TOP
  $('.ga-ev-n-12').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_今日のおともだち_TOP',
      event_label: product_name,
    });
  });

  // 今日のおともだち 詳細
  $('.ga-ev-n-13').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_今日のおともだち_詳細',
      event_label: product_name,
    });
  });

  // 今日のおともだち 種類一覧
  $('.ga-ev-n-14').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_今日のおともだち_種類一覧',
      event_label: product_name,
    });
  });

  // 今日のおともだち テーマ一覧
  $('.ga-ev-n-15').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_今日のおともだち_テーマ一覧',
      event_label: product_name,
    });
  });

  // 今日のおともだち 今まで選ばれたこ一覧
  $('.ga-ev-n-16').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_今日のおともだち_今まで選ばれたこ一覧',
      event_label: product_name,
    });
  });

  // 今日のおともだち うちのこ一覧
  $('.ga-ev-n-17').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_今日のおともだち_うちのこ一覧',
      event_label: product_name,
    });
  });

  // 今日のおともだち お気に入りの子一覧
  $('.ga-ev-n-18').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_今日のおともだち_お気に入りの子一覧',
      event_label: product_name,
    });
  });

  // 今日のおともだち 投稿
  $('.ga-ev-n-19').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_今日のおともだち_投稿',
      event_label: product_name,
    });
  });

  // Q&A 一覧
  $('.ga-ev-n-20').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_Q&A_一覧',
      event_label: product_name,
    });
  });

  // Q&A 詳細一覧
  $('.ga-ev-n-21').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_Q&A_詳細一覧',
      event_label: product_name,
    });
  });

  // Q&A 詳細
  $('.ga-ev-n-22').click(function () {
    var product_name = $(this).find('.ga_product_names').text();
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお届け便_Q&A_詳細',
      event_label: product_name,
    });
  });

  // ペット手帳からのお知らせ
  $('.ga-ev-p-1').on('click', (e) => {
    const title = $(e.currentTarget).data('title');
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお知らせ',
      event_label: title + ' to ' + e.currentTarget.href,
    });
  });

  // ペット手帳からのお知らせ2
  $('.ga-ev-p-2').on('click', (e) => {
    const title = $(e.currentTarget).data('title');
    return gtag('event', 'click', {
      event_category: 'ペット手帳からのお知らせ',
      event_label: title + ' to ' + e.currentTarget.href,
    });
  });

  // お届け便キャッシュバックキャンペーン
  $('.ga-ev-q-1').on('click', (e) => {
    return gtag('event', 'click', {
      event_category: 'お届け便キャッシュバックキャンペーン',
      event_label: 'お届け便キャッシュバックキャンペーン',
    });
  });

  // ペット保険 見積もり
  $('.ga-ev-i-1').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_アイペット50',
    });
  });

  $('.ga-ev-i-2').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_アイペット70',
    });
  });

  $('.ga-ev-i-3').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_アイペット90',
    });
  });

  $('.ga-ev-i-4').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_アニコム50',
    });
  });

  $('.ga-ev-i-5').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_アニコム70',
    });
  });

  $('.ga-ev-i-6').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_アニコムプチ',
    });
  });

  $('.ga-ev-i-7').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_アニコムシニア50',
    });
  });

  $('.ga-ev-i-8').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_アニコムシニア70',
    });
  });

  $('.ga-ev-i-11').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_楽天s',
    });
  });

  $('.ga-ev-i-12').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_楽天m',
    });
  });

  $('.ga-ev-i-13').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_楽天e',
    });
  });

  $('.ga-ev-i-14').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_楽天l',
    });
  });

  $('.ga-ev-i-15').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_ps50',
    });
  });

  $('.ga-ev-i-16').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_ps70',
    });
  });

  $('.ga-ev-i-17').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_ps100',
    });
  });

  $('.ga-ev-i-18').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_楽天50',
    });
  });

  $('.ga-ev-i-19').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_楽天70',
    });
  });

  $('.ga-ev-i-20').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_見積もり_楽天90(手術、入院プラン)',
    });
  });

  // ペット保険 資料請求
  $('.ga-ev-i-d-0').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_まとめて資料請求',
    });
  });

  $('.ga-ev-i-d-1').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_アイペット50',
    });
  });

  $('.ga-ev-i-d-2').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_アイペット70',
    });
  });

  $('.ga-ev-i-d-3').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_アイペット90',
    });
  });

  $('.ga-ev-i-d-4').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_アニコム50',
    });
  });

  $('.ga-ev-i-d-5').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_アニコム70',
    });
  });

  $('.ga-ev-i-d-6').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_アニコムプチ',
    });
  });

  $('.ga-ev-i-d-7').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_アニコムシニア50',
    });
  });

  $('.ga-ev-i-d-8').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_アニコムシニア70',
    });
  });

  $('.ga-ev-i-d-11').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_楽天s',
    });
  });

  $('.ga-ev-i-d-12').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_楽天m',
    });
  });

  $('.ga-ev-i-d-13').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_楽天e',
    });
  });

  $('.ga-ev-i-d-14').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_楽天l',
    });
  });

  $('.ga-ev-i-d-15').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_ps50',
    });
  });

  $('.ga-ev-i-d-16').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_ps70',
    });
  });

  $('.ga-ev-i-d-17').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_ps100',
    });
  });

  $('.ga-ev-i-d-18').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_楽天50',
    });
  });

  $('.ga-ev-i-d-19').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_楽天70',
    });
  });

  $('.ga-ev-i-d-20').on('click', () => {
    return gtag('event', 'click', {
      event_category: 'ボタン',
      event_label: 'ペット保険_資料請求_楽天90(手術、入院プラン)',
    });
  });
};
